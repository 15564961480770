<template>
  <div v-drag>
    <a-modal v-model:visible="dialogVisible"
             :bodyStyle="bodyStyle"
             :maskClosable="false"
             :closable="false"
             :footer="null"
             width="1050rem">
      <template #title>
        <div class="title">
          反馈
        </div>
      </template>
      <div class="block">
        <span :class="form.category == 0?'isActive':''"
              @click="checkType(0)">
          题目反馈
        </span>
        <span :class="form.category == 1?'isActive':''"
              @click="checkType(1)">
          批改反馈
        </span>
      </div>
      <a-form>
        <a-form-item label="反馈:">
          <a-textarea placeholder="请输入"
                      type="textarea"
                      style="width:100%;height:340rem"
                      v-model:value="form.comment" />
        </a-form-item>
        <a-row>
          <a-form-item style="margin-bottom:10px">
            <span class="desction">
              注：题目有错误或者批改有错误，反馈内容才有效
            </span>
          </a-form-item>
        </a-row>
      </a-form>
      <div class="btn_warp">
        <div @click="handleClose">
          取消
        </div>
        <div @click="save">
          提交
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { message } from "ant-design-vue";
import { topicPress } from '@/api/error.js'

let dialogVisible = ref(false)
let form = reactive({})

const bodyStyle = {
  padding: "0 ",
}

const save = async () => {
  console.log(form)
  if (form.comment == '') {
    message.warning('请输入反馈内容')
    return
  }
  let cForm = JSON.parse(JSON.stringify(form))
  if (cForm.category == 0) {
    delete cForm.user_paper_detail_id
  }
  await topicPress(cForm)
  dialogVisible.value = false
  message.success('反馈成功')
}

const checkType = (num) => {
  form.category = num
}

const setData = (pForm) => {
  console.log('pForm', pForm)
  for (const key in pForm) {
    form[key] = pForm[key]
  }
  console.log('form', form)
}

const handleClose = () => {
  dialogVisible.value = false
}

const collect = () => {
  if (topic.has_favorite) {
    message.warning('已经收藏过啦')
    return
  }
  let form = {
    user_paper_detail_id: topic.user_paper_detail_id,
    tags: ''
  }
  // this.$refs.CollectRef.form = form
  // this.$refs.CollectRef.dialogVisible = true
}

defineExpose({
  dialogVisible,
  setData
})
</script>

<style lang="scss" scoped>
.block {
  display: flex;
  margin-bottom: 20px;
  padding: 0 20rem;
  span {
    width: 150rem;
    height: 60rem;
    font-size: 24rem;
    font-weight: bold;
    color: #666666;
    border-radius: 10rem;
    background: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      margin-left: 30rem;
    }
  }
}
.isActive {
  background: #007af0 !important;
  color: white !important;
}

.desction {
  font-size: 24rem;
  font-weight: bold;
  color: #ff6147;
}

.btn_warp {
  height: 70rem;
  border-top: 1px solid #e5e5e5;
  display: flex;
  // position: absolute;
  bottom: 0;
  width: 100%;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(1) {
      font-size: 24rem;
      font-weight: bold;
      color: #999999;
      border-right: 1px solid #e5e5e5;
    }
    &:nth-child(2) {
      font-size: 24rem;
      font-weight: bold;
      color: #2196f3;
    }
  }
}
.ant-form {
  padding: 0 20rem;
}
:deep .ant-form-item-label > label {
  font-size: 24rem;
  font-weight: 500;
  color: #333333;
}

:deep .ant-input {
  font-size: 24rem;
  font-weight: 500;
  color: #999999;
}
.title {
  font-size: 24rem !important;
}
</style>