<template>
  <div v-drag>
    <a-modal title="收藏"
             v-model:visible="dialogVisible"
             @ok="save"
             @cancel="handleClose">
      <!--   v-dialogDrag
             :close-on-click-modal="false"
             :append-to-body="true"
             width="500px"
             :before-close="handleClose" -->
      <!-- <div style="padding:10px 0;">
        可以添加多个标签，标签以空格分开。不需要标签的直接点击"确定"。
      </div> -->
      <a-row :gutter="20"
             style="margin-bottom:15px">
        <a-col :span="23">
          <span style="padding:15px">标签</span>
          <a-select style="width:85%"
                    v-model:value="form.question_favorite_type_id"
                    placeholder="请选择"
                    mode="multiple"
                    :options='tagList.map(item=>({label:item.type_name,value:item.question_favorite_type_id}))'>

          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="23">
          <span style="padding:15px">笔记</span>
          <a-textarea clearable
                      style="width:85%"
                      placeholder="请输入"
                      :rows="4"
                      v-model:value="form.tags"></a-textarea>
        </a-col>
      </a-row>
      <template #footer>
        <span class="dialog-footer">
          <div @click="handleClose">取消</div>
          <div @click="save">确定</div>
        </span>
      </template>
    </a-modal>
  </div>
</template>

<script> 
import { collectTopic, collectTagList } from "@/api/collect.js"
import { message } from "ant-design-vue"
export default {

  data () {
    return {
      dialogVisible: false,
      tagList: [],
      form: {}
    }
  },
  mounted () {
    this.getTagList()
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    async save () {
      const { data } = await collectTopic(this.form)
      this.$emit('refresh')
      this.$message.success('收藏成功')
      this.handleClose()
    },
    async getTagList () {
      const { data } = await collectTagList(this.form)
      this.tagList = data.list

    }



  }
}
</script>

<style lang="scss" scoped>
// ::v-deep .a-dialog__header {
//   font-size: 20px;
//   font-weight: bold;
//   color: black;
//   text-align: center;
// }

// ::v-deep .a-dialog__headerbtn .a-dialog__close {
//   color: white;
//   background: #cccccc;
//   border-radius: 50%;
//   padding: 4px;
// }
.dialog-footer {
  // border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // margin-bottom: -30rem;
  div {
    flex: 1;
    font-weight: bold;
    font-size: 24rem;
    // padding: 18rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:nth-child(2) {
      color: #2196f3;
      border-left: 1px solid #e5e5e5;
    }
  }
}
</style>