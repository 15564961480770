<template>
  <div>
    <div class="image_warp"
         @mouseleave="mouseover">
      <img :src="imageList[index]"
           ref="imageRef"
           v-drag1
           :style="{
           'top':`${scale*120}px`,
           'left':`${scale*120}px`,
           'right':`${scale*120}px`,
           'bottom':`${scale*120}px`,
           'width':imgWidth?`${imgWidth * scale}px`:''
           }"
           class="img_warp_Sss">
    </div>

    <div class="operyion_warp"
         v-if="imageList.length">
      <span @click="refresh">
        <img src="@/static/correctself/icon-shuaxin@2x.png"
             alt="">
      </span>
      <span @click="xiao">
        <img src="@/static/correctself/icon-xiao@2x.png"
             alt="">
      </span>
      <span @click="da">
        <img src="@/static/correctself/icon-da@2x.png"
             alt="">
      </span>
    </div>
    <div class="image_list">
      <img v-for="(item,idx) in imageList"
           :key="idx"
           @click="showImg(idx)"
           :src="imageList[idx]"
           :class="{'item':true,'isActive':idx == index}"
           alt="">
    </div>
  </div>
</template>

<script setup>
import Vue from 'vue'
import { ref, watch, getCurrentInstance, onMounted } from 'vue'
let { imageList } = defineProps({
  imageList: {
    type: Array,
    default: []
  }
})
const vDrag1 = {
  mounted (el, binding) {
    let isDown = false
    let x = 0;
    let y = 0;
    let l = 0;
    let t = 0;
    //鼠标按下事件
    el.onmousedown = function (e) {
      // console.log(el);
      e.preventDefault()
      e.stopPropagation();
      //获取x坐标和y坐标
      x = e.clientX;
      y = e.clientY;

      //获取左部和顶部的偏移量
      l = el.offsetLeft;
      t = el.offsetTop;
      //开关打开
      isDown = true
      //设置样式
      el.style.cursor = "move";
    };
    //鼠标移动
    window.onmousemove = function (e) {
      e.preventDefault()
      e.stopPropagation();
      if (isDown == false) {
        return;
      }
      //获取x和y
      let nx = e.clientX;
      let ny = e.clientY;
      //计算移动后的左偏移量和顶部的偏移量
      let nl = nx - (x - l);
      let nt = ny - (y - t);

      el.style.left = nl + "px";
      el.style.top = nt + "px";
    };
    //鼠标抬起事件
    el.onmouseup = function () {
      //开关关闭
      isDown = false
      el.style.cursor = "default";
    };
    document.onmouseup = function () {
      //开关关闭
      isDown = false
      el.style.cursor = "default";
    };
  },
}

let index = ref(0);// 计算第几张图片
let curent = ref(0)// 计算角度
let suoIndex = ref(1)
let scale = ref(1)
let imgWidth = ref(0)
// let imgHeight = ref(0)
let active = ref(false)
const refresh = () => {
  curent.value += 90
  var oimg = document.getElementsByClassName('img_warp_Sss')[0]
  oimg.style.transform = `rotate(${curent.value}deg)`
}
const showImg = (idx) => {
  index.value = idx
}
let imageRef = ref(null)
const xiao = () => {
  if (!imgWidth.value) {
    imgWidth.value = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
  }
  scale.value -= 0.2
  if (scale.value <= 0.2) {
    scale.value = 0.2
  }
  imageRef.value.style.width = imgWidth.value * scale.value + 'px'
  // this.$forceUpdate()
}
const da = () => {
  if (!imgWidth.value) {
    imgWidth.value = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
  }
  scale.value += 0.2
  imageRef.value.style.width = imgWidth.value * scale.value + 'px'
  // this.$forceUpdate()
}
const mouseover = (el) => {
  // this.$isDown = false
}

// watch(index, () => {
//   console.log(1, index.value)
//   curent.value = 0
//   document.getElementsByClassName('img_warp_Sss')[0].style.transform = ''
// })

onMounted(() => {
  imgWidth.value = document.getElementsByClassName('img_warp_Sss')[0].clientWidth
})

</script>

<style lang="scss" scoped>
// div {
//   ::-webkit-scrollbar {
//     display: none; /* Chrome Safari */
//   }
//   scrollbar-width: none; /* Firefox */
// }
.img_warp {
  width: 100%;
  height: 700px;
  display: flex;
  overflow: auto;

  img {
    width: 700px;
  }
}
.img_selector {
  width: 500px;
  margin: 0 auto;
}
.optration {
  margin-top: 15px;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    cursor: pointer;
    margin: 0 5px;
    font-size: 20px;
  }
}

::v-deep .mouse-cover {
  height: 100px !important  ;
}
.image_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
  img {
    width: 100px;
    height: 100px;
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
    border: 1px solid grey;
  }
}
.isActive {
  border: 4px solid #3f9eff !important;
}
.operyion_warp {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3f9eff;
  border-radius: 5px;
  width: 160px;
  height: 44px;
  margin: 10px auto;
  background: #2d2d2d;
  span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
img {
  border-radius: 5px;
}
.image_warp {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: auto;
  height: 700px;
  width: 100%;
}
.img_warp_Sss {
  text-align: center;
  transform: rotate(0);
  transition: all 0.3s;
  position: absolute;
  -webkit-user-drag: none;
}
</style>