import { instance } from '@/utils/http.js'

// 我的试卷-获取试卷列表
export const getMyPaperList = (params) => {
  return instance({
    url: '/api/v1/my/my_paper',
    method: 'get',
    params
  })
}
// 我的试卷-批改试卷
export const correctSelf = (data) => {
  return instance({
    url: '/api/v1/my/correct_self',
    method: 'post',
    data
  })
}