
export const rands = function (id) {
  id = JSON.stringify(id)
  var hash = 0, i, chr, len;
  if (id.length === 0) return hash;
  for (i = 0, len = id.length; i < len; i++) {
    chr = id.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return (hash + '').slice(0, 4) + id
}