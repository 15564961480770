

import { instance } from '@/utils/http.js'

// 答案解析-收藏
export const collectTopic = (data) => {
  return instance({
    url: '/api/v1/problems/add',
    method: 'post',
    data
  })
}

// 答案解析-收藏-标签列表
export const collectTagList = () => {
  return instance({
    url: '/api/v1/my/favorite_type',
    method: 'get'
  })
}


