<template>
  <div class="wrap">
    <TopCard ref="TopCardRef"
             text="批改试卷">
      <template #right>
        <div class="right_card">
          <img src="@/static/doPaper/card.png"
               @click="card"
               alt=""
               title='答题卡'>
        </div>
      </template>
    </TopCard>
    <div class="content">
      <div class="topic_warp_backg">
        <LEFTIMAGE ref="imageRef"
                   v-if="imageList.length"
                   :imageList="imageList" />
        <div v-else
             class="zanwu">
          <img src="@/static/none.jpg"
               alt="">
        </div>
      </div>
      <div class="topic_warp_backg1">
        <RIGHTTOPIC ref="topicRef"
                    :topic="info.volist[paperIndex]"
                    :topicList="info.volist"
                    @sub='sub'
                    @previous='previous'
                    @add='next'
                    @refresh='getPaperInfo'
                    @card="card" />
      </div>
    </div>
  </div>

</template>

<script setup>
import TopCard from '@/views/components/top_card/index.vue'
import LEFTIMAGE from './components/image/index.vue'
import RIGHTTOPIC from './components/topic/index.vue'
import { addHeader } from '@/init/index.js'
import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import { ref, onMounted } from 'vue'
import { getAnalysi } from "@/api/paper.js"
let imageList = ref([])
// let topicList = ref([])
let info = ref({ volist: [] })
let judge_status = ref('')
let paperIndex = ref(0)
let CollectRef = ref(null)
let topicRef = ref(null)
const router = useRouter()
const route = useRoute()
onMounted(() => {
  // 我的试卷,答题详情
  judge_status.value = window.sessionStorage.getItem('judge_status')

  if (window.sessionStorage.getItem('myTestPaperAnalsis')) {
    info.value = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsis'))
    paperIndex.value = JSON.parse(window.sessionStorage.getItem('myTestPaperAnalsisIndex'))
    setCheckId()
    imageList.value = info.value.images.map(item => {
      return addHeader(item)
    })
  } else {
    getPaperInfo()
  }
  if (window.sessionStorage.getItem('myTestPaperAnalsisIndex')) {
    paperIndex.value = Number(window.sessionStorage.getItem('myTestPaperAnalsisIndex'))
  }
})
const getPaperInfo = async () => {

  const { data } = await getAnalysi({
    user_paper_id: route.query.user_paper_id
  })
  for (let index = 0; index < data.volist.length; index++) {
    const item = data.volist[index];
    // if (isChooseList.indexOf(item.question_type_id) != -1) {
    //   res.data.volist.splice(index, 1)
    //   index--
    // }
    if (item.user_score == null) {
      item.user_score = 0
    }
    item.answerId = index + 1
  }

  info.value = data
  data.images = data.images ? data.images : []
  imageList.value = data.images.map(item => {
    return addHeader(item)
  })

}
const card = () => {
  window.sessionStorage.setItem('myTestPaperAnalsisIndex', paperIndex.value)
  window.sessionStorage.setItem('myTestPaperAnalsis', JSON.stringify(info.value))
  router.push('/selfCard?selfcCorrect=1')
}
const previous = () => {
  if (paperIndex.value == 0) {
    return
  }
  paperIndex.value -= 1
}
const next = () => {
  if (info.value.volist.length - 1 == paperIndex.value) {
    card()
    return
  }
  paperIndex.value += 1
}
const setCheckId = () => {
  info.value.volist[paperIndex.value].isChecked = 1
}
const sub = (num) => {
  let row = info.value.volist[paperIndex.value]
  row.user_score = Number(row.user_score) || 0
  setCheckId()
  row.user_score = Number(row.user_score) || 0
  row.user_score = Number(row.user_score) + num
  if (Number(row.user_score) > Number(row.question_score)) {
    row.user_score = Number(row.question_score)
  }
  if (Number(row.user_score) < 0) {
    row.user_score = 0
  }
  console.log(11)
}
// const collect = () => {
//   if (info.value.volist[paperIndex.value].has_favorite) {
//     message.warning('已经收藏过啦')
//     return
//   }
//   let form = {
//     user_paper_detail_id: info.value.volist[paperIndex.value].user_paper_detail_id,
//     tags: ''
//   }
//   topicRef.value.FormRef.value.form = form
//   topicRef.vakue.FormRef.value.dialogVisible = true
// }

</script>

<style lang="scss" scoped>
.right_card {
  margin-right: 70rem;
  img {
    width: 36rem;
    height: 36rem;
    margin-right: 30rem;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
}
.content {
  display: flex;
  height: calc(100vh - 112rem);
  .topic_warp_backg {
    padding: 0 20rem;
    flex: 1;
  }
  .topic_warp_backg1 {
    padding: 0 20rem;

    flex: 1;
  }
}
.zanwu {
  img {
    width: 100%;
  }
}
</style>