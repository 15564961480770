import { instance } from '@/utils/http.js'

// 整卷-获取试卷列表
export const getWholePaperList = (params) => {
  return instance({
    url: '/api/v1/cp/source_lst',
    method: 'get',
    params
  })
}

// 获取试卷类型
export const getPaperType = () => {
  return instance({
    url: '/api/v1/cp/type',
    method: 'get'
  })
}

// 知识点组卷
export const getKnowledgePaper = (data) => {
  return instance({
    url: '/api/v1/knowledge/knowledge_train',
    method: 'post',
    data
  })
}
// 知识点弱点组卷
export const getKnowledgeWeakPaper = (data) => {
  return instance({
    url: '/api/v1/knowledge/weakness_train',
    method: 'post',
    data
  })
}
// 能力素养组卷
export const getCapalilityTrain = (data) => {
  return instance({
    url: '/api/v1/knowledge/capalility_train',
    method: 'post',
    data
  })
}


// 教师试卷
export const getTeacherPaper = (data) => {
  return instance({
    url: '/api/v1/teacher/do_two',
    method: 'post',
    data
  })
}

// 整卷做卷-模板做卷
export const getWholePaper = (data) => {
  return instance({
    url: '/api/v1/cp/dopaper',
    method: 'post',
    data
  })
}

// 章节做卷
export const getChapterPaperApi = (data) => {
  return instance({
    url: '/api/v1/chapter/create',
    method: 'post',
    data
  })
}

// 保存答案-中途做到一半退出
export const saveAnswer = (data) => {
  return instance({
    url: '/api/v1/cp/submit_answer',
    method: 'post',
    data
  })
}

// 提交试卷
export const submitPaper = (data) => {
  return instance({
    url: '/api/v1/my/submit_answer',
    method: 'post',
    data
  })
}

// 继续做卷
export const continuePaper = (data) => {
  return instance({
    url: '/api/v1/my/continue_paper',
    method: 'post',
    data
  })
}

// 获取试卷详情-答案解析
export const getAnalysi = (params) => {
  return instance({
    url: '/api/v1/my/analysis',
    method: 'get',
    params
  })
}

// 模板组卷
export const getTemplatePaper = (data) => {
  return instance({
    url: '/api/v1/module/create',
    method: 'post',
    data
  })
}


// 模板组卷获取地址
export const getTemplateDir = (params) => {
  return instance({
    url: '/api/v1/module/district',
    method: 'get',
    params
  })
}







